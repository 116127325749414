/*
 * Copyright LaserFlare Studio
 * https://laserflare.net
 * You may not copy or use this code for your own
 * Developed and maintained by MadFox
 */

@keyframes lds-double-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes lds-double-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes lds-double-ring_reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes lds-double-ring_reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.lds-double-ring {
    position: relative;
}

.lds-double-ring div {
    box-sizing: border-box;
}

.lds-double-ring > div {
    position: absolute;
    width: 168px;
    height: 168px;
    top: 16px;
    left: 16px;
    border-radius: 50%;
    border: 8px solid #000;
    border-color: #ff0000 transparent #ff0000 transparent;
    -webkit-animation: lds-double-ring 1s linear infinite;
    animation: lds-double-ring 1s linear infinite;
}

.lds-double-ring > div:nth-child(2),
.lds-double-ring > div:nth-child(4) {
    width: 148px;
    height: 148px;
    top: 26px;
    left: 26px;
    -webkit-animation: lds-double-ring_reverse 1s linear infinite;
    animation: lds-double-ring_reverse 1s linear infinite;
}

.lds-double-ring > div:nth-child(2) {
    border-color: transparent #e77400 transparent #e77400;
}

.lds-double-ring > div:nth-child(3) {
    border-color: transparent;
}

.lds-double-ring > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.lds-double-ring > div:nth-child(3) div:before,
.lds-double-ring > div:nth-child(3) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: -8px;
    left: 72px;
    background: #ff0000;
    border-radius: 50%;
    box-shadow: 0 160px 0 0 #ff0000;
}

.lds-double-ring > div:nth-child(3) div:after {
    left: -8px;
    top: 72px;
    box-shadow: 160px 0 0 0 #ff0000;
}

.lds-double-ring > div:nth-child(4) {
    border-color: transparent;
}

.lds-double-ring > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.lds-double-ring > div:nth-child(4) div:before,
.lds-double-ring > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: -8px;
    left: 62px;
    background: #e77400;
    border-radius: 50%;
    box-shadow: 0 140px 0 0 #e77400;
}

.lds-double-ring > div:nth-child(4) div:after {
    left: -8px;
    top: 62px;
    box-shadow: 140px 0 0 0 #e77400;
}

.lds-double-ring {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}